import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
// import QRCodeIcon from 'src/assets/images/QRCodeIcon.jpg';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  feedBack: icon('ic_thumb'),
  qrcode: icon('ic_qrcode'),
  adduser: icon('ic_addclient')
};

const MANAGE_STORE_CHILED = [
  { title: 'list', path: PATH_DASHBOARD.manageStore.list },
  { title: 'manage', path: PATH_DASHBOARD.manageStore.new },
];

const navConfig = [
  {
    items: [
      {
        title: 'Business Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        access: ['SystemAdmin', 'ClientAdmin', 'StoreAdmin', 'BackOffice'],
      },
      {
        title: 'Customer Dashboard',
        path: PATH_DASHBOARD.general.banking,
        icon: ICONS.dashboard,
        access: ['CustomerUser', 'SystemAdmin', 'ClientAdmin', 'StoreAdmin', 'BackOffice'],
      },
      {
        title: 'Workflow Dashboard',
        path: PATH_DASHBOARD.workflow.root,
        icon: ICONS.dashboard,
        access: ['Manufacturer', 'Distributor', 'Dealer', 'Influencer'],
      },
      {
        title: 'Member Dashboard',
        path: PATH_DASHBOARD.workflow.cust,
        icon: ICONS.dashboard,
        access: ['Manufacturer', 'Influencer'],
      },
      {
        title: 'Analytics',
        path: PATH_DASHBOARD.analytics.root,
        icon: ICONS.analytics,
        access: ['SystemAdmin', 'ClientAdmin', 'StoreAdmin', 'BackOffice'],
        info: (
          <Label color="info" startIcon={<Iconify icon="clarity:beta-line" />}>
            NEW
          </Label>
        ),
        children: [
          { title: 'Analytics', path: PATH_DASHBOARD.analytics.view },
          {
            title: 'Reports',
            path: PATH_DASHBOARD.analytics.report,
          },
        ],
      },
      {
        title: 'Campaigns',
        path: PATH_DASHBOARD.campaign.root,
        icon: ICONS.chat,
        access: ['SystemAdmin', 'BackOffice'],
        children: [
          { title: 'analytics', path: PATH_DASHBOARD.general.booking },
          { title: 'manage', path: PATH_DASHBOARD.campaign.root },
        ],
      },
      {
        title: 'Feedbacks',
        path: PATH_DASHBOARD.feedback.insight,
        icon: ICONS.feedBack,
        access: ['SystemAdmin', 'ClientAdmin', 'StoreAdmin', 'BackOffice'],
      },
      {
        title: 'QR Codes',
        path: PATH_DASHBOARD.qr.root,
        icon: ICONS.qrcode,
        access: ['SystemAdmin', 'ClientAdmin', 'BackOffice'],
        info: (
          <Label color="info" startIcon={<Iconify icon="clarity:beta-line" />}>
            NEW
          </Label>
        ),
      },
      {
        title: 'Workflow List Request',
        path: PATH_DASHBOARD.manageRequestportal.list,
        icon: ICONS.invoice,
        access: ['Manufacturer', 'Distributor', 'Dealer'],
      },
      {
        title: 'Members',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        access: ['SystemAdmin', 'ClientAdmin', 'StoreAdmin'],
        children: [
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'manage', path: PATH_DASHBOARD.user.new },
        ],
      },
      {
        title: 'Stores',
        path: PATH_DASHBOARD.manageStore.root,
        icon: ICONS.cart,
        access: ['SystemAdmin', 'ClientAdmin', 'StoreAdmin'],
        children: MANAGE_STORE_CHILED,
      },
      {
        title: 'Clients',
        path: PATH_DASHBOARD.clients.root,
        icon: ICONS.adduser,
        access: ['SystemAdmin'],
        children: [
          { title: 'List', path: PATH_DASHBOARD.clients.list },
          { title: 'Onboard', path: PATH_DASHBOARD.clients.onboard },
        ],
      },
      {
        title: 'Cashier Portal',
        path: PATH_DASHBOARD.cashierportal.root,
        icon: ICONS.invoice,
        access: ['SystemAdmin', 'ClientAdmin', 'StoreAdmin'],
        children: [
          { title: 'create invoice', path: PATH_DASHBOARD.cashierportal.new },
          {
            title: 'issue reward',
            path: PATH_DASHBOARD.cashierportal.rev,
            info: (
              <Label color="info" startIcon={<Iconify icon="clarity:beta-line" />}>
                NEW
              </Label>
            ),
          },
        ],
      },
      {
        title: 'Manage Request',
        path: PATH_DASHBOARD.manageRequestportal.root,
        icon: ICONS.invoice,
        access: ['Distributor', 'Dealer', 'Influencer'],
        children: [
          {
            title: 'List Request',
            path: PATH_DASHBOARD.manageRequestportal.list,
          },
          {
            title: 'Submit Request',
            path: PATH_DASHBOARD.manageRequestportal.submit,
          },
          {
            title: 'Redeem Request',
            path: PATH_DASHBOARD.manageRequestportal.redeem,
          },
        ],
      },
      {
        title: 'Other',
        path: PATH_DASHBOARD.other.root,
        icon: ICONS.invoice,
        access: ['SystemAdmin', 'ClientAdmin', 'StoreAdmin', 'Manufacturer'],
        children: [
          { title: 'import', path: PATH_DASHBOARD.other.import },
          // { title: 'Update', path: PATH_DASHBOARD.other.import },
        ],
      },
    ],
  },
];

export default navConfig;
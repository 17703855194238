import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
// hooks
// import useOffSetTop from '../../hooks/useOffSetTop';
// config
// import { HEADER } from '../../config-global';
//
// import Header from './Header';

// ----------------------------------------------------------------------

export default function DICompactLayout() {
//   const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <Container sx={{ backgroundColor: '#F2F2F2' }}>
      <Stack
        sx={{
          m: 'auto',
          maxWidth: 420,
          minHeight: '100vh',
          backgroundColor: '#F2F2F2',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Outlet />
      </Stack>
    </Container>
  );
}
